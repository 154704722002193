import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PageHeader from '@common/src/pageheader/pageheader'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import { categoryAction } from '@common/src/service/cloud/catalog'
import { STATUS_OPTIONS, INITIAL_PAGINATION_PROPS, ALL_OPTION } from '../../../constants/constants'
import { calcOffset } from '@common/src/common/helper'
import SearchBox from '@common/src/common/inputbox/searchBox'
import { useQuery } from '@apollo/client'
import { GET_ALL_CATEGORIES } from '@common/src/lib/graphql/query/catalog'
import CategoryForm from './categoryForm'
import DataTable from '@common/src/common/Table/DataTable'
import FilterDropdown from '@common/src/common/inputbox/filterDrop'


const Modifier = () => {
  const toast = useToast()
  const { selectedCompany } = useSelector((state) => state.auth)
  const [searchText, setSearchText] = useState('')
  const [categories, setCategories] = useState([])
  const [catStatusLoader, setCatStatusLoader] = useState({ loading: false, id: null })
  const [isEditOpt, setIsEditOpt] = useState(false)
  const [catData, setCatData] = useState(null)
  const [pagination, setPagination] = useState(INITIAL_PAGINATION_PROPS)
  const { isVisible, activeComponent, openOffCanvas, closeOffCanvas, offCanvasTitle } = useFloatingCanvas()
  const [listInfo, setListInfo] = useState('No Categories found.')
  const [catStatus, setCatStatus] = useState('ALL')

  const { data, refetch, loading } = useQuery(GET_ALL_CATEGORIES, {
    variables: {
      offset: calcOffset(searchText, pagination.currentPage, pagination.limit, pagination.totalRecords),
      limit: pagination.limit,
      companyId: selectedCompany?.value,
      status: catStatus,
      search: searchText || undefined,
    },
    onCompleted: (data) => {
      if (searchText) {
        setPagination((prevPagination) => ({
          ...prevPagination,
          totalRecords: data?.categoryQuery?.totalRecords,
          offset: calcOffset(searchText, pagination.currentPage, pagination.limit, pagination.totalRecords),
          currentPage: 1,
        }))
      }
    },
  })
  useEffect(() => {
    if (data) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: data.categoryQuery.totalPages,
      }))
      setCategories(data.categoryQuery.rows)
    } else {
      setCategories([])
      searchText && setListInfo('No Category found for your search.')
    }
  }, [data])
  const onSuccess = () => {
    closeOffCanvas()
    refetch()
  }
  useEffect(() => {
    refetch({
      offset: calcOffset(searchText, pagination.currentPage, pagination.limit, pagination.totalRecords),
      limit: pagination.limit,
      ...(searchText && { search: searchText }),
      ...catStatus && { status: catStatus },
      ...(selectedCompany?.id && { companyId: parseInt(selectedCompany.id) }),
    });
    setPagination((prevPagination) => ({
      ...prevPagination,
      currentPage: 1
    }))
  }, [searchText, selectedCompany, catStatus])

  const onChangeStatus = async (record) => {
    setCatStatusLoader({ loading: true, id: record.id })
    const newStatus = (record.status === STATUS_OPTIONS[0].value) ? STATUS_OPTIONS[1].value : STATUS_OPTIONS[0].value;
    const formData = {
      ...record,
      status: newStatus,
      ...(record?.id && { id: parseInt(record?.id) }),
      companyId: parseInt(record.companyId)
    }
    try {
      await categoryAction(formData, true)
      toast.success(`Category status updated  to ${newStatus.toLowerCase()}`)
      onSuccess()
      setCatStatusLoader({ loading: false, id: null })
    } catch (error) {
      setCatStatusLoader({ loading: false, id: null })
      toast.error('Error updating Category')
    }
  }

  const onEditItem = (data) => {
    openOffCanvas('addCategory', 'Edit Category')
    setIsEditOpt(true)
    setCatData(data)
  }
  const handleAddEvent = () => {
    openOffCanvas('addCategory', 'Add Category')
    setCatData(null)
    setIsEditOpt(false)
  }
  return (
    <>
      <PageHeader activepage="Catalog Management" mainpage="Categories" currentpage="Categories" />
      <div className="grid gap-6 grid-cols-12">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="flex justify-between box-header items-center">
              <div className="box-title">Categories</div>
              <div className="flex items-center">
                <FilterDropdown
                  className="mr-2 w-[200px]"
                  options={[ALL_OPTION.status, ...STATUS_OPTIONS]}
                  value={STATUS_OPTIONS.find((opt) => opt.value === catStatus) || ALL_OPTION.status}
                  onChange={(e) => { setCatStatus(e.value.toUpperCase()) }}
                />
                <SearchBox
                  value={searchText}
                  placeHolder="Search by name or POS ID"
                  onChange={(e) => setSearchText(e.target.value)}
                  classes='!py-2 !px-2 !mr-2'
                />
              </div>
              <div className="flex items-center">
                <button
                  onClick={handleAddEvent}
                  type="button"
                  className="ti-btn !py-2 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary ms-2 !mb-0 h-10"
                >
                  <i className="ri-add-line font-semibold align-middle me-1"></i>Add Category
                </button>
              </div>
            </div>

            <DataTable
              loading={loading}
              rows={categories}
              columns={[ 'Name', 'Description', 'POS ID', 'Sort Order', 'Status']}
              pagination={pagination}
              setPagination={setPagination}
              onEditItem={onEditItem}
              onChangeStatus={onChangeStatus}
              toggleLoader={catStatusLoader}
              info={listInfo}
              innerPages={false}
            />
          </div>
        </div>
      </div>
      <FloatingCanvas onClose={closeOffCanvas} title={offCanvasTitle} isVisible={isVisible} >
        {activeComponent === 'addCategory' && (
          <CategoryForm
            data={catData}
            isEdit={isEditOpt}
            onComplete={onSuccess}
            companyId={selectedCompany?.value}
          />
        )}
      </FloatingCanvas>
    </>
  )
}

export default Modifier
