import { gql } from "@apollo/client";

export const GET_ALL_CUSTOMERS=gql`
query getAllCustomers($limit: Int, $offset: Int, $filters: CustomerFiltersInput, $search: String) {
  customerListQuery(limit: $limit, offset: $offset, filters: $filters, search: $search) {
    limit
    offset
    rows {
      email
      firstName
      id
      lastName
      phoneCode
      phoneNumber
      status
      createdAt
      lastBlockedBy
      lastBlockedReason
    }
    totalPages
    totalRecords
  }
}

`