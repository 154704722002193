import { valuePlusFormatter } from "@common/src/common/helper";
import { getOrderStatusColor, ORDER_PAYMENT_STATUS, ORDER_STATUS_LIST, PAYMENT_MODES } from "./tabContents/common";
import PropTypes from 'prop-types';
import ConfirmModal from "@common/src/common/modal/confirmModal";


const PaymentInfo = (props) => {
    const { refundAction, orderDetails, modalLoaders, isRetry, setIsRetry } = props;
    let currency = orderDetails?.meta?.config?.currency?.currencyCode
    return (
        <div className='box pb-6' >
            <div className='box-body flex justify-between'>
                <div className='mt-6 w-full'>
                    <h5 className='font-bold'>Payment Details</h5>
                    <div className='mt-3'>
                        <table className='w-full'>
                            <tbody>
                                <tr>
                                    <td className='py-1'>Payment Type</td>
                                    <td className='py-1 font-bold text-right'><h4>{valuePlusFormatter(orderDetails.paymentMode)}</h4></td>
                                </tr>
                                <tr>
                                    <td className='py-1'>Payment Status</td>
                                    <td className='py-1 font-bold text-right'>
                                        <h6>
                                            <span className={`badge ${getOrderStatusColor(ORDER_STATUS_LIST[orderDetails?.paymentStatus])}`}>
                                                {ORDER_STATUS_LIST[orderDetails?.paymentStatus]}
                                            </span>
                                        </h6>
                                    </td>
                                </tr>
                                {(orderDetails.paymentMode === PAYMENT_MODES.VALUE_PLUS ||
                                    orderDetails.paymentMode === PAYMENT_MODES.VALUE_PLUS_ONLINE) && (
                                        <>
                                            <tr>
                                                <td className='py-1'>Wallet Amount</td>
                                                <td className='py-1 text-right'>{`${currency} ${parseFloat(orderDetails?.walletUsed).toFixed(2)}`}</td>
                                            </tr>
                                            {orderDetails.paymentMode === PAYMENT_MODES.VALUE_PLUS_ONLINE && (
                                                <tr>
                                                    <td className='py-1'>Online Amount</td>
                                                    <td className='py-1 text-right'>{`${currency} ${parseFloat(orderDetails?.onlinePayable).toFixed(2)}`}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td className='py-1'>Value Plus Card Number</td>
                                                <td className='py-1 text-right'>{orderDetails?.walletMeta?.card?.cardNumber || '-'}</td>
                                            </tr>
                                        </>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {[ORDER_PAYMENT_STATUS[0].value, ORDER_PAYMENT_STATUS[1].value, ORDER_STATUS_LIST.CANCELLED].includes(orderDetails.orderStatus) &&
                orderDetails?.paymentMode !== ORDER_STATUS_LIST.COD && orderDetails.paymentStatus === ORDER_STATUS_LIST.PAID &&
                (
                    <>
                        <div className='box-body'>
                            <table className='w-full border border-gray-300'>
                                <thead>
                                    {orderDetails?.walletTransactionId && orderDetails?.paymentId ? (
                                        <tr className='bg-gray-200 border-b border-gray-300'>
                                            <th className='border-r border-gray-300 text-center p-1'>REFUND</th>
                                        </tr>
                                    ) : (

                                        <tr className='bg-gray-200 border-b border-gray-300'>
                                            {(orderDetails?.paymentMode === PAYMENT_MODES.VALUE_PLUS || orderDetails?.paymentMode === PAYMENT_MODES.VALUE_PLUS_ONLINE) && (
                                                <th className='border-r border-gray-300 text-center p-1'>WALLET REFUND</th>
                                            )}
                                            {(orderDetails?.paymentMode === PAYMENT_MODES.ONLINE || orderDetails?.paymentMode === PAYMENT_MODES.VALUE_PLUS_ONLINE) && (
                                                <th className='border-r border-gray-300 text-center p-1'>ONLINE REFUND</th>
                                            )}
                                        </tr>
                                    )}
                                </thead>
                                <tbody>

                                    {orderDetails?.walletTransactionId && orderDetails?.paymentId ? (
                                        <RefundInfo orderDetails={orderDetails} setIsRetry={setIsRetry} />
                                    ) : (
                                        <tr>
                                            {(orderDetails?.paymentMode === PAYMENT_MODES.VALUE_PLUS || orderDetails?.paymentMode === PAYMENT_MODES.VALUE_PLUS_ONLINE) && (
                                                <RefundStatus
                                                    condition={orderDetails?.orderStatus === ORDER_STATUS_LIST.CANCELLED}
                                                    orderStatus={orderDetails?.orderStatus}
                                                    transactionId={orderDetails?.walletTransactionId}
                                                    amount={`${parseFloat(orderDetails?.walletUsed).toFixed(2)}`}
                                                    currency={currency}
                                                    onClickRetry={() => setIsRetry({ show: true, type: '' })}
                                                />
                                            )}
                                            {(orderDetails?.paymentMode === PAYMENT_MODES.ONLINE || orderDetails?.paymentMode === PAYMENT_MODES.VALUE_PLUS_ONLINE) && (
                                                <RefundStatus
                                                    condition={orderDetails?.orderStatus === ORDER_STATUS_LIST.CANCELLED}
                                                    orderStatus={orderDetails?.orderStatus}
                                                    transactionId={orderDetails?.paymentId}
                                                    amount={`${parseFloat( orderDetails?.paymentMode === PAYMENT_MODES.ONLINE ? orderDetails?.total : orderDetails?.onlinePayable).toFixed(2)}`}
                                                    currency={currency}
                                                    onClickRetry={() => setIsRetry({ show: true, type: '' })}
                                                />
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <ConfirmModal
                            title="REFUND ORDER"
                            message="Are you sure you want to refund?"
                            btnTxt="Yes, Refund"
                            action={() => refundAction()}
                            onCancel={() => { setIsRetry({ show: false, type: '' }) }}
                            isOpen={isRetry}
                            setIsOpen={setIsRetry}
                            loading={modalLoaders?.refund}
                        />
                    </>
                )
            }

        </div >
    );
};
const RefundStatus = ({ condition, transactionId, amount, currency, onClickRetry, orderStatus }) => (
    <td className='border-r border-gray-300 text-center p-1'>
        {transactionId ? (
            <>
                <i className={`ri-${ orderStatus === ORDER_PAYMENT_STATUS[0].value ? 'time-line text-info' : 'error-warning-fill text-danger'} text-xl`} />
                <p className={`${ orderStatus === ORDER_PAYMENT_STATUS[0].value ? 'text-warning' : 'text-danger'}`}>
                    { orderStatus === ORDER_STATUS_LIST.CANCELLED ? 'Click here to initiate refund process' :
                        <>
                            {( orderStatus === ORDER_PAYMENT_STATUS[0].value) &&
                                <span className="spinner-border text-info">Refund in progress</span>

                            }
                            {( orderStatus === ORDER_PAYMENT_STATUS[2].value) && 'Refund faild, Click to retry'}
                        </>
                    }
                </p>

                <button type="button" className={`ti-btn bg-danger text-white !font-medium`} onClick={onClickRetry}>
                    {condition ? 'REFUND' : 'RETRY'}
                </button>
            </>
        ) : (
            <>
                <i className="ri-checkbox-circle-fill text-green text-xl" />
                <p className='text-success'>Successfully Refunded amount of {`${currency} ${amount}`}</p>
            </>
        )}
    </td>
);

const RefundInfo = ({ orderDetails, setIsRetry }) => (
    <tr>
        <td className='border-r border-gray-300 text-center p-1'>
            <i className={`ri-${orderDetails?.orderStatus === ORDER_PAYMENT_STATUS[0].value ? 'time-line text-info' : 'error-warning-fill text-danger'} text-xl`} />
            <p className={`${orderDetails?.orderStatus === ORDER_PAYMENT_STATUS[0].value ? 'text-warning' : 'text-danger'}`}>
                {orderDetails?.orderStatus === ORDER_STATUS_LIST.CANCELLED ? 'Click here to initiate refund process' :
                    <>
                        {(orderDetails?.orderStatus === ORDER_PAYMENT_STATUS[0].value) &&
                            <span className="spinner-border text-info">Refund in progress</span>
                        }
                        {(orderDetails?.orderStatus === ORDER_PAYMENT_STATUS[2].value) && 'Refund faild, Click to retry'}
                    </>
                }
            </p>
            <button type="button" className={`ti-btn bg-danger text-white !font-medium mt-2`} onClick={() => setIsRetry({ show: true, type: '' })}>
                {orderDetails?.orderStatus === ORDER_STATUS_LIST.CANCELLED ? 'REFUND' : 'RETRY'}
            </button>
        </td>
    </tr>
);

RefundInfo.propTypes = {
    orderDetails: PropTypes.shape({
        orderStatus: PropTypes.string,
        paymentStatus: PropTypes.string
    }).isRequired,
    setIsRetry: PropTypes.func
}
RefundStatus.propTypes = {
    condition: PropTypes.bool.isRequired,
    transactionId: PropTypes.string,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    onClickRetry: PropTypes.func.isRequired,
    orderStatus: PropTypes.string
};
PaymentInfo.propTypes = {
    refundAction: PropTypes.func.isRequired,
    orderDetails: PropTypes.shape({
        paymentMode: PropTypes.string.isRequired,
        walletUsed: PropTypes.number,
        onlinePayable: PropTypes.number,
        total: PropTypes.number,
        walletMeta: PropTypes.shape({
            card: PropTypes.shape({
                cardNumber: PropTypes.string
            })
        }),
        orderStatus: PropTypes.string,
        paymentStatus: PropTypes.string,
        walletTransactionId: PropTypes.string,
        paymentId: PropTypes.string,
        meta: PropTypes.shape({
            config: PropTypes.shape({
                currency: PropTypes.shape({
                    currencyCode: PropTypes.string
                })
            })
        })
    }).isRequired,
    modalLoaders: PropTypes.shape({ refund: PropTypes.bool }),
    isRetry: PropTypes.bool,
    setIsRetry: PropTypes.func
};
export default PaymentInfo;
