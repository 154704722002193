import * as Yup from 'yup';

export const availabilityValidationSchema = () => {
  return {
    dateStart: Yup.date().when(['timed', '$dateAll'], {
      is: (timed, dateAll) => timed && !dateAll,
      then: (schema) => schema.required('Start date is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    dateEnd: Yup.date()
      .when(['timed', '$dateAll'], {
        is: (timed, dateAll) => timed && !dateAll,
        then: (schema) => schema.required('End date is required'),
        otherwise: (schema) => schema.notRequired(),
      })
      .test('is-after-start-date', 'End date must be after start date', function (value) {
        const { dateStart } = this.parent;

        // Ensure both dates are valid
        if (!dateStart || !value) {
          return true; // Skip validation if either date is not set
        }

        // Skip validation if both dates are the same
        if (new Date(dateStart).toDateString() === new Date(value).toDateString()) {
          return true; // Validation passes if dates are the same
        }

        // Validate if endDate is after startDate
        return new Date(value).getTime() > new Date(dateStart).getTime();
      }),
    timeStart: Yup.string().when(['timed', '$timeAll'], {
      is: (timed, timeAll) => timed && !timeAll,
      then: (schema) => schema.required('Start time is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    timeEnd: Yup.string()
      .when(['timed', '$timeAll', '$daysAll'], {
        is: (timed, timeAll, daysAll) => timed && !timeAll,
        then: (schema) => schema.required('End time is required'),
        otherwise: (schema) => schema.notRequired(),
      })
      .test('is-after-start-time', 'End time must be after start time for the same day', function (value) {
        const { dateStart, dateEnd, timeStart } = this.parent;

        // If dates are the same, validate time
        if (dateStart && dateEnd && new Date(dateStart).toDateString() === new Date(dateEnd).toDateString()) {
          if (!timeStart || !value) return true; // Skip validation if either time is not set

          // Parse time strings into Date objects for comparison
          const [startHour, startMinute] = timeStart.split(':').map(Number);
          const [endHour, endMinute] = value.split(':').map(Number);

          const startTime = new Date(0, 0, 0, startHour, startMinute);
          const endTime = new Date(0, 0, 0, endHour, endMinute);

          return endTime > startTime; // Ensure endTime is after startTime
        }

        // If dates are different, skip time validation
        return true;
      }),
    days: Yup.array().when(['timed', '$daysAll'], {
      is: (timed, daysAll) => timed && !daysAll,
      then: (schema) => schema.min(1, 'At least one day is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  };
};