import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import SelectBox from '@common/src/common/selectbox/selectbox';
import InputBox from '@common/src/common/inputbox/inputbox';
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm';

const getValidationSchema = (modGroupType) => {  
  return Yup.object({  
    modifierGroup: Yup.object()  
      .test('has-value', 'Modifier group is required', (obj) => Object.keys(obj).length > 0)  
      .required('Modifier group is required'),  
    quantity: modGroupType === 'addon' ? Yup.number() : Yup.number().required('Quantity is required').min(0, 'Quantity must be greater than zero'),  

  });  
};  

const ModifierGroupAdd = ({ id, options, data, isEdit, setTableData, tableData, onClose }) => {
  const [initialValues, setInitialValues] = useState({
    modifierGroup: {},
    quantity: '',
  });
  const [modGroupType, setModGroupType] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {  
    if (data) {  
      // Populate initial values for edit mode  
      setInitialValues({
        modifierGroup: {
          id: data.modGroupId || data.modifierGroup?.id || '',
          value: data.modGroupName || data.modifierGroup?.value || '',
        },
        quantity: data.quantity || '',
      });  
      setModGroupType(data?.modGroupType || null); // Set initial modGroupType
    }else{
      // Reset initial values for add mode  
      setInitialValues({  
        modifierGroup: {},  
        quantity: '',  
        });  
      setModGroupType(null); // Reset modGroupType  
    }
  }, [data]);

  const handleSubmit = async (values, actions) => {
    setIsLoading(true)
    actions.setSubmitting(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const formData = {
      modGroupId: values.modifierGroup.id,
      modGroupName: values.modifierGroup.value,
      quantity: values.quantity || null,
      modGroupType: values.modifierGroup.modGroupType
    };

    if (isEdit) {
      // Update the existing modifier by index
      const updatedTableData = [...tableData];
      const index = tableData.findIndex((item) => item.modGroupId === data.modGroupId);
      if (index !== -1) {
        updatedTableData[index] = { ...updatedTableData[index], ...formData };
      }
      setTableData(updatedTableData);
    } else {
      // Add a new modifier
      setTableData([...tableData, formData]);
    }

    // Reset the form to initial values
    actions.resetForm({
      values: {
        modifierGroup: {},
        quantity: '',
      },
    });
    setModGroupType(null)
    actions.setSubmitting(false);
    setIsLoading(false);
    onClose();
  };

  const handleModifierGroupChange = (e, setFieldValue) => {  
    setFieldValue('modifierGroup', { id: e.value, value: e.label, modGroupType: e.modGroupType });  
    setModGroupType(e.modGroupType);  

    // Clear the quantity field if the selected modifier group type is 'addon'  
    if (e.modGroupType === 'addon') {  
      setFieldValue('quantity', ''); // Clear the quantity field  
    }  
  };  

  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema(modGroupType)}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
      autoClose={false}
      loading={isLoading}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <div className="grid grid-cols-1 gap-6">
          <SelectBox
            label="Modifier Group"
            name="modifierGroup"
            options={options}
            value={options.find((option) => option.value === values.modifierGroup.id) || null}    
            onChange={(e) => handleModifierGroupChange(e, setFieldValue)}
            errorText={errors.modifierGroup && touched.modifierGroup ? errors.modifierGroup : null}  
            required  
          />  
          <InputBox
            label="Quantity"
            name="quantity"
            type="number"
            placeholder="Enter Quantity"
            value={values.quantity}
            onChange={(e) => setFieldValue('quantity', e.target.value)}
            errorText={errors.quantity && touched.quantity ? errors.quantity : null}
            required={modGroupType !== 'addon'}
            disabled={modGroupType === 'addon'}
          />
          {modGroupType === 'addon' && (
              <p className="text-sm text-gray-500">
              *Quantity is not required for addon modifiers.
              </p>  
          )}
        </div>
      )}
    </OffCanvasForm>
  );
};

ModifierGroupAdd.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.object,
  isEdit: PropTypes.bool,
  setTableData: PropTypes.func,
  tableData: PropTypes.array,
  onClose: PropTypes.func
};

export default ModifierGroupAdd;