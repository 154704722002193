import Dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { ROLE, STATUS } from '../constants/constants'
import { PAYMENT_MODES } from '@hub/container/appOrders/tabContents/common'

Dayjs.extend(utc)
Dayjs.extend(timezone)

export function convertTime(time) {
  const [timePart, modifier] = time.split(' ')
  let [hours, minutes] = timePart.split(':').map(Number)

  if (modifier === 'PM' && hours !== 12) {
    hours += 12
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0
  }

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}
export const getInitials = (firstName = '', lastName = '') => {
  const firstInitial = firstName.slice(0, 1).toUpperCase()
  const lastInitial = lastName.slice(0, 1).toUpperCase()
  return `${firstInitial}${lastInitial}`.trim()
}

/**
 * Normalizes the value for the SelectBox component.
 * @param {string|array} value - The value(s) to normalize.
 * @returns {object|array|null} - The normalized value object(s) or null if not found.
 */
export const normalizeSelectBoxValue = (value) => {
  if (Array.isArray(value)) {
    return value.map((val) => ({ value: val, label: val }))
  }
  if (typeof value === 'string') {
    return { value, label: value }
  }
  return null
}

export const timeStamptoDate = (time_stamp_date, timezone, format) => {
  return time_stamp_date ? Dayjs.tz(time_stamp_date.toDate(), timezone).format(format || 'YYYY-MM-DDTHH:mm') : ''
}
export const isHub = () => {
  return import.meta.env.VITE_PORTAL === ROLE.ROLE_TYPE_HUB
}

// Helper function to normalize a date to midnight
export const normalizeDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export const transformToValueLabel = (values, options) => {
  return options.filter((option) => values.includes(option.value))
}

export const normalizeDateFormat = (date) => {
  if (!date) return null
  const d = new Date(date)
  const year = d.getFullYear()
  const month = String(d.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const day = String(d.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
export const getCdnUrl = (imageUrl) => {
  if (imageUrl?.startsWith(`https://firebasestorage.googleapis.com/v0/b/${import.meta.env.VITE_FIREBASE_STORAGE_BUCKET}/o/`)) {
    return imageUrl.replace(
      `https://firebasestorage.googleapis.com/v0/b/${import.meta.env.VITE_FIREBASE_STORAGE_BUCKET}/o/`,
      import.meta.env.VITE_CDN_HOST
    )
  }
  return imageUrl
}

export const getCurrentTimeWithTimeZone = (timeZone) => {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    timeZone,
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(new Date());

  // Convert the formatted date to ISO format
  const isoDate = formattedDate.replace(
    /^(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})$/,
    '$3-$1-$2T$4:$5:$6'
  );

  // Return the timestamp
  return new Date(isoDate).getTime();
};

/**
 * Format a given date-time in milliseconds to a specified format.
 *
 * @param {number} dateTime - The date-time in milliseconds.
 * @param {boolean} [timeStamp=false] - If true, the dateTime is a Firestore Timestamp.
 * @param {string} [format='DD-MM-YYYY hh:mm A'] - The format to use for the result.
 * @return {string} The formatted date-time.
 */
export const formatDateTime = (dateTime, timeZone, timeStamp = false, format = 'DD-MM-YYYY hh:mm A') => {
  let formattedDate = '';
  if (timeStamp) {
    formattedDate = Dayjs
      .unix(dateTime?.seconds)
      .add(dateTime?.nanoseconds / 1000000, 'millisecond')
      .tz(timeZone)
      .format(format)
  } else {
    formattedDate = Dayjs.unix(dateTime / 1000).tz(timeZone).format(format)
  }

  return formattedDate
}


/**
 * Calculates the new offset when the user searches and the current page is at the end.
 * If there is no search query or the current page is not at the end, returns the current offset.
 *
 * @param {string} searchQuery - The search query.
 * @param {number} currentPage - The current page.
 * @param {number} resultsPerPage - The number of results per page.
 * @param {number} totalResults - The total number of results.
 * @returns {number} The new offset.
 */
export const calcOffset = (searchQuery, currentPage, resultsPerPage, totalResults) => {
  // Check if there is a search query and the current page is at the end
  if (searchQuery && currentPage === Math.ceil(totalResults / resultsPerPage)) {
    // Calculate the new offset for the first page
    const newOffset = (currentPage - 1) * resultsPerPage;
    return newOffset;
  }

  // Return the current offset if there is no search query or the current page is not at the end
  return (currentPage - 1) * resultsPerPage;
}

/**
 * Calculates the status of a promotion based on the start and end dates.
 * If the promotion has not started or has ended, the status is OFFLINE.
 * If the promotion is currently active, the status is LIVE.
 *
 * @param {Timestamp} startDate - The start date of the promotion.
 * @param {Timestamp} endDate - The end date of the promotion.
 * @param {string} timeZone - The time zone of the promotion.
 * @param {string} defaultStatus - The default status if the start and end dates are not provided.
 * @returns {string} The status of the promotion.
 */
export const getStatus = (startDate, endDate, timeZone, defaultStatus = STATUS.OFFLINE) => {
  if (!endDate || !startDate) return defaultStatus;

  // Convert Firestore Timestamps to JavaScript Date objects
  const startDateTime = startDate.toDate();
  const endDateTime = endDate.toDate();
  const currentDate = Dayjs().tz(timeZone).toDate();

  // Remove seconds and milliseconds from the current time
  const currentDateWithoutSeconds = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes()
  );

  const startDateTimeWithoutSeconds = new Date(
    startDateTime.getFullYear(),
    startDateTime.getMonth(),
    startDateTime.getDate(),
    startDateTime.getHours(),
    startDateTime.getMinutes()
  );

  const endDateTimeWithoutSeconds = new Date(
    endDateTime.getFullYear(),
    endDateTime.getMonth(),
    endDateTime.getDate(),
    endDateTime.getHours(),
    endDateTime.getMinutes()
  );

  // Check if the current time is strictly after the end time
  if (currentDateWithoutSeconds > endDateTimeWithoutSeconds) {
    return STATUS.OFFLINE;
  }

  // Check if the current time is before the start time
  if (currentDateWithoutSeconds < startDateTimeWithoutSeconds) {
    return STATUS.OFFLINE;
  }

  // Otherwise, the status is LIVE
  return STATUS.LIVE;
};


export const addOrKeepSeconds = (timeStr) => {
  if (timeStr.includes(":")) {
    const parts = timeStr.split(":");
    if (parts.length === 2) {
      return `${timeStr}:00`; // Add seconds if only hours and minutes
    } else if (parts.length === 3) {
      return timeStr; // Keep as is if already has seconds
    }
  }
  return timeStr; // Return original if no colons or other unexpected format
}

export const valuePlusFormatter = (value)=> {
  if (value == PAYMENT_MODES.VALUE_PLUS_ONLINE) {
    return 'WALLET + ONLINE';
  } else if (value == PAYMENT_MODES.VALUE_PLUS) {
    return 'WALLET';
  } else {
    return value; 
  }
}