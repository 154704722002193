import { useQuery } from '@apollo/client'
import SearchBox from '@common/src/common/inputbox/searchBox'
import { GET_ALL_COMPANIES } from '@common/src/lib/graphql/query/company'
import Pageheader from '@common/src/pageheader/pageheader'
import Pagination from '@hub/components/common/Pagination/pagination'
import React, { useEffect, useState } from 'react'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas.jsx'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas.js'
import CompanyForm from './companyForm'
import { getPaymentOptions } from '@common/src/service/firebase/api'
import { INITIAL_PAGINATION_PROPS } from '@hub/constants/constants'
import { calcOffset } from '@common/src/common/helper'

const CompanyList = () => {
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()
  const [list, setList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [info, setInfo] = useState('No companies found.')
  const [rowData, setRowData] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [paymentOptions, setPaymentOptions] = useState([])
  const [pagination, setPagination] = useState(INITIAL_PAGINATION_PROPS)

  const { data, refetch, loading } = useQuery(GET_ALL_COMPANIES, {
    variables: {
      limit: pagination.limit,
      offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
      search: searchQuery || undefined,
    },
  })

  useEffect(() => {
    fetchPaymentOptions()
    refetch({
      limit: pagination.limit,
      offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
      search: searchQuery || undefined,
    }).then(({ data }) => {
      if (data) {
        setList(data.companyQuery.rows);
        setPagination((prev) => ({
          ...prev,
          totalRecords: data.companyQuery.totalRecords,
          totalPages: data.companyQuery.totalPages,
        }));
      } else {
        setList([]);
        setInfo('No results found for your search.');
      }
    });
  }, [pagination.currentPage, searchQuery]);

  const onAddClick = () => {
    setRowData(null)
    setIsEdit(false)
    openOffCanvas('addCompany', 'Add Company')
  }

  const onComplete = () => {
    refetch()
    closeOffCanvas()
  }
  const onEditClick = (storeData) => {
    setRowData(storeData);
    setIsEdit(true);
    openOffCanvas('addCompany', 'Edit Company');
  };
  const fetchPaymentOptions = async () => {
    const paymentOptionsData = await getPaymentOptions(true)
    setPaymentOptions(paymentOptionsData || [])
  }
  const showTaxConfig = (taxType) => {
    if (taxType === null) return '';
    return taxType ? 'Inclusive' : 'Exclusive';
  };
  

  const showTaxType = (item) => {
    if (item) {
      return (
        <span className="badge rounded-pill bg-info text-white">
          {item === 'NO_TAX' ? 'NO TAX' : item.toUpperCase()}
        </span>
      )
    } else {
      return ''
    }
  }
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPagination((prev) => ({
      ...prev,
      currentPage: 1, // Reset to the first page
      offset: 0, // Reset offset
    }));
  };
  return (
    <>
      <Pageheader currentpage="Companies" activepage="Company Management " mainpage="Companies" />
      <div className="grid grid-cols-12 gap-6">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="box-header flex items-center justify-between">
              <div className="box-title">Companies</div>
              <SearchBox
                placeHolder="Search by company name"
                value={searchQuery}
                onChange={handleSearchChange}
                classes='h-10'
                width='w-1/3'
              />
              <div className="flex items-center">
                <button
                  type="button"
                  className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary ms-2 h-10"
                  onClick={onAddClick}
                >
                  <i className="ri-add-line font-semibold align-middle me-1"></i>Add Company
                </button>
              </div>
            </div>

            <div className="box-body">
              <div className="table-responsive mb-4">
                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <thead>
                    <tr className="border-b">
                      <th scope="col" className="text-start">
                        Sl.No
                      </th>
                      <th scope="col" className="text-start">
                        Company Name
                      </th>
                      <th scope="col" className="text-start">
                        Country
                      </th>
                      <th scope="col" className="text-start">
                        Payment Gateway
                      </th>
                      <th scope="col" className="text-start">
                        Tax Type
                      </th>
                      <th scope="col" className="text-start">
                        Tax Option
                      </th>
                      <th scope="col" className="text-start">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {list.length > 0 ? (
                          list.map((item) => (
                            <tr className="company-list border-b" key={item.id}>
                              <td>{(pagination.currentPage - 1) * pagination.limit + list.indexOf(item) + 1}</td>
                              <td>
                                <div className="flex items-center">
                                  <div className="font-semibold">{item?.name?.en}</div>
                                </div>
                              </td>
                              <td>
                                <div className="flex items-center">
                                  <div className="font-semibold">{item?.config?.country?.country}</div>
                                </div>
                              </td>
                              <td>{paymentOptions.find((option) => option?.value === item?.paymentGatewayId)?.label || ''}</td>
                              <td>{showTaxType(item?.config?.taxConfig?.taxType)}</td>
                              <td>{showTaxConfig(item?.config?.taxConfig?.isInclusive)}</td>
                              <td>
                                <div className="space-x-1 rtl:space-x-reverse flex">
                                  <button
                                    onClick={() => onEditClick(item)}
                                    className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm"
                                  >
                                    <i className="ri-pencil-line"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              <div className="alert alert-info">{info}</div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} setPagination={setPagination} rows={list} />
            </div>
          </div>
        </div>
      </div>
      <FloatingCanvas onClose={closeOffCanvas} isVisible={isVisible} title={offCanvasTitle}>
        {activeComponent === 'addCompany' && <CompanyForm data={rowData} isEdit={isEdit} onComplete={onComplete} />}
      </FloatingCanvas>
    </>
  )
}

export default CompanyList
