import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PageHeader from '@common/src/pageheader/pageheader'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import Toggle from '@common/src/common/Toggle/Toggle'
import { modifierAction } from '@common/src/service/cloud/catalog'
import { STATUS_OPTIONS, INITIAL_PAGINATION_PROPS, ALL_OPTION } from '../../../constants/constants'
import ModifierForm from './modifierForm'
import Pagination from '@hub/components/common/Pagination/pagination'
import { calcOffset } from '@common/src/common/helper'
import SearchBox from '@common/src/common/inputbox/searchBox'
import { useQuery } from '@apollo/client'
import { GET_ALL_MODIFIERS } from '@common/src/lib/graphql/query/catalog'
import LoaderSm from '@common/src/common/loader/loader-sm'
import FilterDropdown from '@common/src/common/inputbox/filterDrop'


const Modifier = () => {
  const toast = useToast()
  const { isVisible, activeComponent, openOffCanvas, closeOffCanvas, offCanvasTitle } = useFloatingCanvas()
  const { selectedCompany } = useSelector((state) => state.auth)
  const [searchText, setSearchText] = useState('')
  const [modList, setModList] = useState([])
  const [modStatusLoader, setModStatusLoader] = useState({ loading: false, id: null })
  const [isEditOpt, setIsEditOpt] = useState(false)
  const [modifierData, setModifierData] = useState(null)
  const [pagination, setPagination] = useState(INITIAL_PAGINATION_PROPS)
  const [modStatus, setModStatus] = useState('ALL')
  const [listInfo, setListInfo] = useState('No Modifiers found.')

  const { data, refetch, loading } = useQuery(GET_ALL_MODIFIERS, {
    variables: {
      offset: calcOffset(searchText, pagination.currentPage, pagination.limit, pagination.totalRecords),
      limit: pagination.limit,
      companyId: selectedCompany?.value,
      status: modStatus,
      search: searchText || undefined,
    },
    onCompleted: (data) => {
      if (searchText) {
        setPagination((prevPagination) => ({
          ...prevPagination,
          totalRecords: data?.modifierQuery?.totalRecords,
          offset: calcOffset(searchText, pagination.currentPage, pagination.limit, pagination.totalRecords),
          currentPage: 1,
        }))
      }
    },
  })

  useEffect(() => {
    if (data) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: data.modifierQuery.totalPages,
      }))
      setModList(data.modifierQuery.rows)
    } else {
      setModList([])
      searchText && setListInfo('No Modifiers found for your search.')
    }
  }, [data])
  const onSuccess = () => {
    closeOffCanvas()
    refetch()
  }
  useEffect(() => {
    refetch({
      offset: calcOffset(searchText, pagination.currentPage, pagination.limit, pagination.totalRecords),
      limit: pagination.limit,
      ...(searchText && { search: searchText }),
      ...(selectedCompany?.id && { companyId: parseInt(selectedCompany.id) }),
      ...(modStatus && { status: modStatus }),
    });
    setPagination((prevPagination) => ({
      ...prevPagination,
      currentPage: 1
    }))
  }, [searchText, selectedCompany, modStatus])

  const onChangeStatus = async (record) => {
    setModStatusLoader({ loading: true, id: record.id })
    const newStatus = (record.status === STATUS_OPTIONS[0].value) ? STATUS_OPTIONS[1].value : STATUS_OPTIONS[0].value;
    const formData = {
      ...record,
      status: newStatus,
      modStatus: newStatus,
      companyId: selectedCompany?.value,
      ...(record?.id && { id: parseInt(record?.id) }),
    }
    try {
      await modifierAction(formData, true)
      toast.success(`Modifier status updated  to ${newStatus.toLowerCase()}`)
      onSuccess()
      setModStatusLoader({ loading: false, id: null })
    } catch (error) {
      toast.error('Error updating Modifier')
    }
  }

  const onEditItem = (data) => {
    openOffCanvas('addModifier', 'Edit Modifier')
    setIsEditOpt(true)
    setModifierData(data)
  }
  const handleAddEvent = () => {
    openOffCanvas('addModifier', 'Add Modifier')
    setModifierData(null)
    setIsEditOpt(false)
  }
  return (
    <>
      <PageHeader activepage="Catalog Management" mainpage="Modifiers" currentpage="Modifiers" />
      <div className="grid gap-6 grid-cols-12">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="flex justify-between box-header items-center">
              <div className="box-title">Modifiers</div>
              <div className="flex items-center">
                <FilterDropdown
                  className="mr-2 w-[200px]"
                  options={[ALL_OPTION.status, ...STATUS_OPTIONS]}
                  value={STATUS_OPTIONS.find((opt) => opt.value === modStatus) || ALL_OPTION.status}
                  onChange={(e) => { setModStatus(e.value.toUpperCase()) }}
                />
                <SearchBox
                  value={searchText}
                  placeHolder="Search by name or POS ID"
                  classes='!py-2 !px-2 !mr-2'
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <div className="flex items-center">
                <button
                  onClick={handleAddEvent}
                  className="ti-btn !py-2 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary ms-2 !mb-0 h-10"
                  type="button"
                >
                  <i className="ri-add-line font-semibold align-middle me-1"></i>Add Modifiers
                </button>
              </div>
            </div>

            <div className="box-body">
              <div className="table-responsive mb-4">
                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <thead>
                    <tr className="border-b border-defaultborder">
                      <th className="text-start">Sl.No</th>
                      <th className="text-start">Name</th>
                      <th className="text-start">POS ID</th>
                      <th className="text-start">Sort Order</th>
                      <th className="text-start">Status</th>
                      <th className="text-start">
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {modList.length > 0 ? (
                          modList.map((i) => (
                            <tr className="company-list border-b" key={i.id}>
                              <td>{(pagination.currentPage - 1) * pagination.limit + modList.indexOf(i) + 1}</td>
                              <td>
                                <div className="flex items-center">
                                  {i?.modItemName?.en}
                                </div>
                              </td>
                              <td>
                                <div className="flex items-center">
                                  {i?.posId}
                                </div>
                              </td>
                              <td>
                                <div className="flex items-center">
                                  {i?.sortOrder}
                                </div>
                              </td>
                              <td>
                                <div className="flex items-center">
                                  {i?.status}
                                </div>
                              </td>
                              <td>

                                <td>
                                  <div className="space-x-1 rtl:space-x-reverse flex items-center">
                                    <button onClick={() => onEditItem(i)} className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm">
                                      <i className="ri-pencil-line"></i>
                                    </button>
                                    <div className="relative inline-flex items-center">
                                      <Toggle label="" onChange={() => onChangeStatus(i)} value={i.status === STATUS_OPTIONS[0].value} />
                                      {(modStatusLoader.loading && modStatusLoader.id === i.id) && (
                                        <div className="absolute inset-0 bg-opacity-50 bg-gray-100 w-full ml-3">
                                          <LoaderSm />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={8} >
                              <div className="alert alert-info">{listInfo}</div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                pagination={pagination}
                setPagination={setPagination}
                rows={modList}
                innerPages={false}
              />
            </div>
          </div>
        </div>
      </div>
      <FloatingCanvas onClose={closeOffCanvas} title={offCanvasTitle} isVisible={isVisible} >
        {activeComponent === 'addModifier' && (
          <ModifierForm
            data={modifierData}
            isEdit={isEditOpt}
            onComplete={onSuccess}
            companyId={selectedCompany?.value}
          />
        )}
      </FloatingCanvas>
    </>
  )
}

export default Modifier
