import { useQuery } from '@apollo/client'
import { calcOffset, convertTime, getCurrentTimeWithTimeZone } from '@common/src/common/helper'
import { GET_ALL_STORES } from '@common/src/lib/graphql/query/store'
import Pageheader from '@common/src/pageheader/pageheader'
import AvatarImage from '@hub/components/common/ImageHolders/avatarImage'
import Pagination from '@hub/components/common/Pagination/pagination'
import React, { useEffect, useState } from 'react'

import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas.jsx'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas.js'
import StoreForm from './storeForm'
import SearchBox from '@common/src/common/inputbox/searchBox'
import { storeStatusAction } from '@common/src/service/cloud/catalog'
import Toggle from '@common/src/common/Toggle/Toggle'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { INITIAL_PAGINATION_PROPS, STATUS_OPTIONS } from '@hub/constants/constants'
import LoaderSm from '@common/src/common/loader/loader-sm'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { getCompanies } from '../../../shared/src/service/firebase/api'
import TextBreaker from '@common/src/common/Table/textBreaker'


const StoreList = () => {
  const [list, setList] = useState([])
  const [pagination, setPagination] = useState(INITIAL_PAGINATION_PROPS)
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()
  const [rowData, setRowData] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [info, setInfo] = useState('No stores found.')
  const [statusLoader, setStatusLoader] = useState({ loading: false, id: null })
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const toast = useToast()


  const { data, refetch, loading } = useQuery(GET_ALL_STORES, {
    variables: {
      limit: pagination.limit,
      offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
      ...(selectedCompany?.id && { companyId: parseInt(selectedCompany.id) }),
      ...(searchQuery && { search: searchQuery }),
    },
  })

  const onAddClick = () => {
    setRowData(null)
    setIsEdit(false)
    openOffCanvas('addStore', 'Add Store')
  }

  const onEditClick = (storeData) => {
    setRowData(storeData)
    setIsEdit(true)
    openOffCanvas('addStore', 'Edit Store')
  }

  const onStatusToggleClick = (record) => {
    setStatusLoader({ loading: true, id: record.id })
    const newStatus = (record.status === STATUS_OPTIONS[0].value) ? STATUS_OPTIONS[1].value : STATUS_OPTIONS[0].value;
    handleStoreUpdate(newStatus, record)
  }

  const handleStoreUpdate = async (status, record) => {
    const formData = { companyId: parseInt(record.company.id), status, ...(record?.id && { id: parseInt(record?.id) }) }
    try {
      await storeStatusAction(formData)
      setStatusLoader({ loading: false, id: null })
      refetch()
      toast.info(`Store ${status === STATUS_OPTIONS[0].value ? 'unblocked' : 'blocked'} successfully.`)
    } catch (error) {
      setStatusLoader({ loading: false, id: null })
      toast.error('Error blocking Store')
    }
  }
  const onComplete = () => {
    refetch()
    closeOffCanvas()
  }

  const handleCompanyChange = (comp) => {
    setSelectedCompany(comp);
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companies = await getCompanies();

        const companyList = [
          { label: 'All Companies', value: '', id: '' },
          ...companies.map((company) => ({
            label: company.label,
            value: company.value,
            id: company?.data?.id?.toString()
          }))
        ];

        setCompanyOptions(companyList);
      } catch (error) {
        console.error('Failed to fetch companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    if (data) {
      setList(data.storeListQuery.rows)
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: data.storeListQuery.totalPages,
      }))
    } else {
      setList([])
      setInfo('No stores match your search.')
    }
  }, [data])

  useEffect(() => {
    refetch({
      limit: pagination.limit,
      offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
      ...(selectedCompany?.id && { companyId: parseInt(selectedCompany.id) }),
      ...(searchQuery && { search: searchQuery }),
    });
    setPagination((prevPagination) => ({
      ...prevPagination,
      currentPage: 1
    }))
  }, [selectedCompany, searchQuery]);
  function isTimeWithinRange(open, close, current) {
    const openT = new Date(`1970-01-01T${open}Z`);
    const closeT = new Date(`1970-01-01T${close}Z`);
    const currentT = new Date(`1970-01-01T${current}Z`);

    if (openT > closeT) {
      return currentT >= openT || currentT <= closeT
    }
    return currentT >= openT && currentT <= closeT
  }
  return (
    <>
      <Pageheader currentpage="Stores" activepage="Store Management " mainpage="Stores" />
      <div className="grid grid-cols-12 gap-6">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="box-header flex items-center justify-between">
              <div className="box-title">Stores</div>
              <SearchBox
                placeHolder="Search by store name / location"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                classes="py-2 px-3 !ti-form-input !rounded-none !rounded-s-sm !focus:z-10 h-10"
                width='w-1/3'
                
              />
              <SelectBox
                options={companyOptions}
                value={selectedCompany}
                onChange={(value) => handleCompanyChange(value)}
                placeHolder="Select Company"
                className="w-60 h-10"
              />
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={onAddClick}
                  className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary ms-2 h-10 flex items-center"
                >
                  <i className="ri-add-line font-semibold align-middle me-1"></i>Add Store
                </button>
              </div>
            </div>

            <div className="box-body">
              <div className="table-responsive mb-4">
                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <thead>
                    <tr>
                      <th scope="col" className="text-start">
                        Sl.No
                      </th>
                      <th scope="col" className="text-start">
                        Store Name
                      </th>
                      <th scope="col" className="text-start">
                        Location
                      </th>
                      <th scope="col" className="text-start">
                        Company Name
                      </th>
                      <th scope="col" className="text-start">
                        Services
                      </th>
                      <th scope="col" className="text-start">
                        Session Status
                      </th>
                      <th scope="col" className="text-start">
                        Operating Hours
                      </th>
                      <th scope="col" className="text-start">
                        Address
                      </th>
                      <th scope="col" className="text-start">
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {list.length > 0 ? (
                          list.map((item) => {
                            const timeZone = item.company.config.country.timezone || 'Asia/Kolkata';
                            const currentTime = getCurrentTimeWithTimeZone(timeZone);
                            const currentTimeString = new Intl.DateTimeFormat('en-US', {
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: false,
                            }).format(new Date(currentTime));
                            return (
                              <tr className="product-list" key={item.id}>
                                <td>{(pagination.currentPage - 1) * pagination.limit + list.indexOf(item) + 1}</td>
                                <td>
                                  <div className="flex items-center">
                                    <div className="me-2">
                                      <AvatarImage src={item?.photo} />
                                    </div>
                                    <div className="font-semibold">{item.name.en}</div>
                                  </div>
                                </td>
                                <td><TextBreaker text={item?.address?.city?.en} /></td>
                                <td><TextBreaker text={item.company?.name?.en} /></td>
                                <td>
                                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                    {item?.services?.map((service) => (
                                      <li key={service}>{service.replace(/_/g, ' ')}</li>
                                    ))}
                                  </ul>
                                </td>
                                <td>
                                  {item.isClosed ? 'Closed' : 'Open'}
                                </td>
                                <td>
                                  <span
                                    className={
                                      `${isTimeWithinRange(item?.open, item?.close, currentTimeString) ?
                                        'bg-success/100' : 'bg-danger'} inline-block rounded-full h-2 w-2 mr-2`
                                    }
                                  >

                                  </span>
                                  {`${convertTime(item?.open)} to ${convertTime(item?.close)}`}</td>
                                <td>
                                  <TextBreaker text={item?.address?.address?.en} />
                                </td>
                                <td>
                                  <div className="space-x-1 rtl:space-x-reverse flex items-center">
                                    <button onClick={() => onEditClick(item)} className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm">
                                      <i className="ri-pencil-line"></i>
                                    </button>
                                    <div className="relative inline-flex items-center">
                                      <Toggle label="" value={item.status === STATUS_OPTIONS[0].value} onChange={() => onStatusToggleClick(item)} />
                                      {(statusLoader.loading && statusLoader.id === item.id) && (
                                        <div className="absolute inset-0 bg-gray-100 bg-opacity-50">
                                          <LoaderSm />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                          )
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              <div className="alert alert-info">{info}</div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} setPagination={setPagination} rows={list} />
            </div>
          </div>
        </div>
      </div>
      <FloatingCanvas onClose={closeOffCanvas} isVisible={isVisible} title={offCanvasTitle}>
        {activeComponent === 'addStore' && <StoreForm data={rowData} isEdit={isEdit} onComplete={onComplete} />}
      </FloatingCanvas>
    </>
  )
}

export default StoreList
