import { useState, useEffect } from 'react'
import PageHeader from '@common/src/pageheader/pageheader'
import Pagination from '@hub/components/common/Pagination/pagination'
import SearchBox from '@common/src/common/inputbox/searchBox'
import { useNavigate } from 'react-router-dom'
import Toggle from '@common/src/common/Toggle/Toggle'
import { useToast } from '@common/src/common/Toast/ToastProvider';
import { calcOffset } from '@common/src/common/helper'
import FilterDropdown from '@common/src/common/inputbox/filterDrop'
import { ALL_OPTION, CARD_STATUS, CARD_STATUS_OPTIONS } from '@hub/constants/constants'
import { getAllStores } from '@common/src/service/firebase/api'
import fetchHelper from '@common/src/lib/fetchHelper'
import ConfirmModal from '@common/src/common/modal/confirmModal'
import InputBox from '@common/src/common/inputbox/inputbox'

const ValuePlusCards = () => {
    const params = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const toast = useToast();
    const [rows, setRows] = useState([])
    const [filters, setFilters] = useState({
        ...params.get('status') && { status: params.get('status') },
        ...params.get('storeId') && { storeId: params.get('storeId') },
        ...params.get('expireStart') && { expireStart: params.get('expireStart') },
        ...params.get('expireEnd') && { expireEnd: params.get('expireEnd') }
    });
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('')
    const [searchQuery, setSearchQuery] = useState(params.get('search') || '');
    const [toggleLoader, setToggleLoader] = useState({ loading: false, id: null })
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const cardsStatusOptions = [ALL_OPTION.status, ...CARD_STATUS_OPTIONS]
    const defaultPagination = {
        limit: parseInt(params.get('limit')) || 20,
        offset: parseInt(params.get('offset')) || 0,
        currentPage: parseInt(params.get('currentPage')) || 1,
        totalPages: 0,
        totalRecords: 0
    }
    const [pagination, setPagination] = useState(defaultPagination)
    const [info, setInfo] = useState('');
    const [modalState, setModalState] = useState({ show: false, type: '', card: null });

    useEffect(() => {
        setLoading(true);
        getAllStores().then(res => {
            setStores([ALL_OPTION.stores, ...res]);
        })
    }, []);
    useEffect(() => {
        fetchData();
    }, [pagination.currentPage, searchQuery, filters]);

    const fetchData = async () => {
        const response = await fetchHelper.get('/card', {
            limit: pagination.limit,
            offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
            ...searchQuery && { search: searchQuery },
            ...filters && { filters: JSON.stringify(filters) }
        });
        if (response?.data?.rows?.length) {
            setRows(response?.data.rows)
            setPagination({
                ...defaultPagination,
                totalRecords: response?.data.totalRecords,
                totalPages: response?.data.totalPages,
            })
        } else {
            setInfo('No Cards Found.');
            setRows([]);
        }
        setLoading(false);
    };
    const onStatusToggle = (e, item) => {
        setModalState({
            show: true,
            type: item.status === CARD_STATUS.BLOCKED ? 'unblock' : 'block', // Determine action based on current status
            card: item
        });
    };

    const handleConfirmAction = async () => {
        const { card, type } = modalState; // Extract type (block/unblock) and card details
        setToggleLoader({ loading: true, id: card.id });
        try {
           const response = await fetchHelper.post(`/card/block-unblock`, {
                id: card.id,
                block: type === 'block' // `true` for block, `false` for unblock
            });


            const errorMessage = response?.error?.message
            if (errorMessage) {
                throw new Error(errorMessage);
            }
            // Update the state to reflect the change
            setRows(prevRows =>
                prevRows.map(row =>
                    row.id === card.id
                        ? { ...row, status: type === 'block' ? CARD_STATUS.BLOCKED : CARD_STATUS.ACTIVE } // Update status
                        : row
                )
            );

            toast.success(`Card status updated to ${type === 'block' ? 'blocked' : 'unblocked'}`, { type: 'success' });
        } catch (error) {
            console.error(error);
            error.message? toast.error(error.message, { type: 'error' }) :
            toast.error('Failed to update card status', { type: 'error' });
        } finally {
            setToggleLoader({ loading: false, id: '' });
            setModalState({ show: false, type: '', card: null });
        }
    };
    // Function to handle the search input change
    const onSearchCard = (query) => {
        // Trim the input value to remove leading/trailing spaces
        let trimmedQuery = query.trim();
        // Remove the `+` symbol if present
        if (trimmedQuery.startsWith('+')) {
            trimmedQuery = trimmedQuery.replace('+', ''); // Remove the `+` symbol
        }

        // If the query starts with a country code (e.g., 91 or 971), extract the part after the space
        const parts = trimmedQuery.split(' '); // Split the string by space
        if (parts.length > 1) {
            trimmedQuery = parts.slice(1).join(''); // Join the remaining parts (in case of multiple spaces)
        }

        // Update the query parameters with the trimmed value
        setSearchQuery(trimmedQuery);

        if (trimmedQuery) {
            params.set('search', trimmedQuery);
        } else {
            params.delete('search');
        }
        params.set('currentPage', 1);
        params.set('offset', 0);
        // Navigate to the new URL with the updated search parameters
        navigate(`?${params.toString()}`);
    };
    const onFilterChange = (e, fieldName) => {
        // Update the search query state
        setFilters({
            ...filters,
            [fieldName]: e.value === 'all' ? undefined : e.value
        });
        // If there is a query, set it as a search parameter
        if (e.value !== 'all') params.set(fieldName, e.value.toString().toUpperCase());
        else params.delete(fieldName);

        // Update the URL with the new offset and currentPage
        params.set('currentPage', 1);
        params.set('offset', 0);
        // Navigate to the new URL with the updated search parameters
        navigate(`?${params.toString()}`);
    };
    const handleApplyFilters = () => {
        if (selectedStartDate && !selectedEndDate) {
            toast.error('Please select the To date.')
        } else if (!selectedStartDate && selectedEndDate) {
            toast.error('Please select the From date.')
        } else {
            fetchData(); // Fetch data only when the button is clicked
        }

    };
    const onDateChange = (type, value) => {
        setFilters({ ...filters, [type]: value });
        params.set(type, value);
        navigate(`?${params.toString()}`);
    }
    const clearFilters = () => {
        setFilters({});
        setSearchQuery('');
        params.delete('search');
        params.delete('expireStart');
        params.delete('expireEnd');
        params.delete('storeId');
        params.delete('status');
        params.set('currentPage', 1);
        params.set('offset', 0);
        setSelectedEndDate('');
        setSelectedStartDate('');
        navigate(`?${params.toString()}`);
    };
    return (
        <>
            <PageHeader currentpage="Value Plus Cards" activepage="Manage Customer" mainpage="Value Plus Cards" />
            <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-12 col-span-12">
                    <div className="box">
                        <div className="flex justify-between box-header items-center">
                            <div className="flex mr-4 w-[27%]">
                                <SearchBox
                                    classes="py-2 px-3 !ti-form-input !rounded-none !rounded-s-sm !focus:z-10"
                                    width="w-full"
                                    placeHolder="Search by card/phone number"
                                    value={searchQuery}
                                    onChange={(e) => onSearchCard(e.target.value)}
                                />
                                <button className="inline-flex flex-shrink-0 justify-center p-2 items-center rounded-e-sm border border-transparent font-semibold bg-primary text-white hover:bg-primary focus:z-10 focus:outline-none focus:ring-0 focus:ring-primary transition-all text-sm"
                                    onClick={() => onSearchCard(searchQuery.trim())} >
                                    <i className="ri-search-line"></i>
                                </button>
                            </div>
                            <div className="flex">
                                <FilterDropdown
                                    className="mr-2 xl:w-[200px] w-full"
                                    options={stores}
                                    value={
                                        filters.storeId ? stores.find((option) => option.value === filters?.storeId)
                                            : ALL_OPTION.stores
                                    }
                                    onChange={(e) => { onFilterChange(e, 'storeId') }}
                                />
                                <FilterDropdown
                                    className="mr-2 xl:w-[200px] w-full"
                                    options={cardsStatusOptions}
                                    value={
                                        filters.status ?
                                            cardsStatusOptions.find((option) => option.value === filters?.status)
                                            : ALL_OPTION.status
                                    }
                                    onChange={(e) => { onFilterChange(e, 'status') }}
                                />

                                <InputBox
                                    type="date"
                                    name="expireStart"
                                    value={selectedStartDate}
                                    onChange={(e) => {
                                        onDateChange('expireStart', e.target?.value);
                                        setSelectedStartDate(e.target.value);
                                    }}
                                    placeholder="Enter Start Date"
                                    id="expireStart"
                                    className="mr-2 min-w-[200px]"
                                />

                                <InputBox
                                    type="date"
                                    name="expireEnd"
                                    value={selectedEndDate}
                                    onChange={(e) => {
                                        onDateChange('expireEnd', e.target?.value);
                                        setSelectedEndDate(e.target.value);
                                    }}
                                    placeholder="Enter End Date"
                                    id="expireEnd"
                                    className="mr-2 min-w-[200px]"
                                />

                                <button
                                    className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary mr-2"
                                    onClick={handleApplyFilters}
                                >
                                    Apply Filter
                                </button>
                                <button
                                    className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-danger mr-2"
                                    onClick={clearFilters}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="table-responsive mb-4">
                                <table className="table whitespace-nowrap table-bordered min-w-full">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-start">
                                                Sl.No
                                            </th>
                                            <th scope="col" className="text-start">
                                                Card Number
                                            </th>
                                            <th scope="col" className="text-start">
                                                Customer Name
                                            </th>
                                            <th scope="col" className="text-start">
                                                Phone Number
                                            </th>
                                            <th scope="col" className="text-start">
                                                Outlet Code
                                            </th>
                                            <th scope="col" className="text-start">
                                                Store Location
                                            </th>
                                            <th scope="col" className="text-start">

                                                Expiry Date
                                            </th>
                                            <th scope="col" className="text-start">
                                                Current Balance
                                            </th>
                                            <th scope="col" className="text-start">
                                                Action(Block/Unblock)
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan={8} className="text-center">
                                                    <div className="d-flex justify-content-center">
                                                        <div className="spinner-border">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {rows?.length > 0 ? (
                                                    rows.map((item) => (
                                                        <tr className="product-list" key={item.id}>
                                                            <td>{(pagination.currentPage - 1) * pagination.limit + rows.indexOf(item) + 1}</td>
                                                            <td>
                                                                <span className="font-semibold">{item?.cardNumber}</span>
                                                            </td>
                                                            <td>
                                                                {(item?.customerId && item?.customer?.firstName === "Anonymous") ? "Anonymous" :
                                                                    <>
                                                                        {item?.customerId ? `${item?.customer?.firstName || '-'} ${item?.customer?.lastName || ''}` : '-'}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td>{item?.cardType === 'IN' ? '+91 ' : '+971 '} {item?.phoneNumber}</td>
                                                            <td>{item?.store?.outletCode}</td>
                                                            <td>
                                                                {item?.store?.address?.city?.en || item?.store?.address?.city?.ar}
                                                            </td>
                                                            <td>{new Date(item?.expireAt).toLocaleDateString('en-GB')}</td>
                                                            <td>{`${item?.currency} ${item?.balance || 0}`}</td>
                                                            <td>
                                                                <div className="relative inline-flex items-center">
                                                                    <Toggle
                                                                        label=""
                                                                        value={item.status === CARD_STATUS.BLOCKED}
                                                                        disabled={toggleLoader.loading && toggleLoader.id === item.id}
                                                                        onChange={(e) => onStatusToggle(e, item)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={9} className="text-center">
                                                            <div className="alert alert-info">
                                                                {info}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                pagination={pagination}
                                setPagination={setPagination}
                                rows={rows}
                                searchQuery={searchQuery}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmModal
                isOpen={modalState}
                setIsOpen={setModalState}
                action={handleConfirmAction}
                title={`Confirm ${modalState.type === 'unblock' ? 'Unblock' : 'Block'} Action`}
                message={`Are you sure you want to ${modalState.type === 'unblock' ? 'unblock' : 'block'} this card?`}
                btnTxt="Confirm"
                loading={toggleLoader.loading}
            />
        </>
    )
}
export default ValuePlusCards
