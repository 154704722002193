import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'

import { CATALOG, GROUP_TYPES, PRICE_LIMIT, STATUS_OPTIONS } from '@hub/constants/constants'
import { getPaginatedData } from '../api'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { modifierGroupAction } from '@common/src/service/cloud/catalog'

const validationSchema = Yup.object({
  name: Yup.object()
    .shape({
      en: Yup.string().required('English name is required'),
      ar: Yup.string().required('Arabic name is required'),
    })
    .required('Name is required'),
  modGroupType: Yup.string().required('Type is required'),
  status: Yup.string().required('Status is required'),
  posId: Yup.string().required('POS ID is required'),
  modifiers: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required('Modifier is required').nullable(),
        price: Yup.number().required('Price is required').typeError('Price must be a number').test('is-positive', 'Price must be positive', (value) => value === null || value === undefined || value >= 0),
      })
    )
    .min(1, 'At least one modifier must be selected'),
})

const ModifierGroupForm = ({ id, data, isEdit, onComplete, companyId }) => {
  const toast = useToast()
  const [modifiersOptions, setModifiersOptions] = useState([])
  const [modifiersList, setModifiersList] = useState([])
  const [initialValues, setInitialValues] = useState({
    name: {},
    description: {},
    status: '',
    modGroupType: '',
    posId: '',
    modLabel: '',
    modifiers: [], // To store selected modifiers
  })
  const fetchModifiersList = async () => {
    const result = await getPaginatedData(companyId, CATALOG.MODIFIER_COLLECTION, 150, '', 'posId')
    const options = result.data.map((modifier) => ({
      value: modifier.id,
      id: modifier.id,
      label: modifier.modItemName ? modifier.modItemName.en : modifier.name.en,
      price: modifier.price,
      status: modifier.status,
    }))
    setModifiersOptions(options)
  }

  // Function to handle adding a new modifier row
  const handleAddModifier = (event, setFieldValue) => {
    event.preventDefault()
    // Check if any of the existing modifiers have an empty price
    const hasEmptyPrice = modifiersList.some(modifier => isNaN(modifier.price) || modifier.price === '' || modifier.price < 0)

    if (hasEmptyPrice) {
      // Show an error message or alert if a price is missing
      toast.error('Please add a price for all modifiers before adding a new one.')
      return // Prevent adding a new modifier
    }

    // If all modifiers have a price, proceed to add a new modifier row
    const newModifier = { id: '', price: '', status: 'ACTIVE' }
    setModifiersList([...modifiersList, newModifier])
    setFieldValue('modifiers', [...modifiersList, newModifier], false)
  }

  // Function to handle deleting a modifier row
  const handleDeleteModifier = (index, setFieldValue) => {
    const updatedList = [...modifiersList]
    updatedList.splice(index, 1) // Remove the row at the given index
    setModifiersList(updatedList)
    setFieldValue('modifiers', updatedList)
  }

  // Function to handle selecting a modifier from the dropdown
  const handleSelectModifier = (index, selectedData, setFieldValue) => {
    const updatedList = [...modifiersList]
    updatedList[index] = selectedData // Update the modifier id in the row
    setModifiersList(updatedList)
    setFieldValue('modifiers', updatedList)
  }

  // Function to handle price input change
  const handlePriceChange = (index, price, setFieldValue) => {
    const updatedList = [...modifiersList]
    updatedList[index] = { ...updatedList[index], price } // Create a new object for the specific modifier and update the price
    setModifiersList(updatedList)
    setFieldValue('modifiers', updatedList)
  }

  // Filter available options to avoid selecting the same modifier twice
  const availableModifiersOptions = modifiersOptions
    .filter((option) => !modifiersList.some((modifier) => modifier.id == option.id))
    .filter((option) => option.status === 'ACTIVE')

  const getSingleModifierGroupData = async () => {
    setInitialValues({
      name: data?.modGroupName || {},
      description: data?.description || {},
      modGroupType: data?.modGroupType || '',
      status: data?.status || STATUS_OPTIONS[0].value,
      posId: data?.posId || '',
      modLabel: data?.modLabel || '',
      modifiers: data?.modifiers || [],
    })
    setModifiersList(data?.modifiers || [])
  }

  useEffect(() => {
    fetchModifiersList()
    getSingleModifierGroupData()
  }, [])

  const handleSubmit = async (values, actions) => {
    const modifiersListData = values.modifiers.map((modifier) => ({
      id: parseInt(modifier.id),
      status: modifier?.status || STATUS_OPTIONS[0].value,
      price: parseFloat(modifier.price) || 0,
    }))

    const formData = {
      modGroupName: values.name,
      description: values.description,
      modGroupType: values.modGroupType,
      status: values.status,
      modLabel: values.modLabel,
      posId: values.posId,
      modifiers: modifiersListData,
      companyId: companyId,
      ...(isEdit && { id: parseInt(data.id) })
    }

    try {
      await modifierGroupAction(formData, isEdit) // Submit the form data

      const toastMessage = isEdit ? 'Modifier group updated successfully!' : 'Modifier group successfully!'
      toast.success(toastMessage) // Show success toast

      actions?.resetForm() // Reset the form after successful submission
      onComplete?.() // Call onComplete callback if provided
      HSOverlay.close(`#${id}`);
    } catch (error) {
      toast.error(isEdit ? 'Error updating Modifier group. Please ensure that the POS ID is unique.' : 'Error creating Modifier group. Please ensure that the POS ID is unique.')
    }
  }

  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
      autoClose={false}
    >
      {({ setFieldValue, values, errors, touched }) => {
        return (
          <div className="grid grid-cols-12 gap-6">
            <div className="xl:col-span-12 col-span-12">
              <div className="box-body add-products !p-0">
                <div className="grid grid-cols-2 gap-6">
                  <MultiLanguageInput
                    type="input"
                    label="Modifier Group Name"
                    id="product-name-add"
                    name="name"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                    required
                  />
                </div>
                <div className="mt-6 grid grid-cols-12 gap-2">
                  <SelectBox
                    className="xl:col-span-6 col-span-12"
                    label="Type"
                    name="modGroupType"
                    options={GROUP_TYPES}
                    value={GROUP_TYPES.find((option) => option.value === values.modGroupType)}
                    onChange={(e) => setFieldValue('modGroupType', e.value)}
                    errorText={errors.modGroupType && touched.modGroupType ? errors.modGroupType : null}
                    required
                  />
                  <InputBox
                    className="xl:col-span-6 col-span-12"
                    label="Label"
                    name="modLabel"
                    type="text"
                    placeholder="Label"
                    value={values.modLabel}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/^\s+/, ''); // Remove leading spaces
                      setFieldValue('modLabel', sanitizedValue); // Update Formik's value
                    }}
                  />
                </div>
                <div className="mt-6 grid grid-cols-12 gap-2">
                  <SelectBox
                    className="xl:col-span-6 col-span-12"
                    label="Status"
                    name="status"
                    options={STATUS_OPTIONS}
                    value={STATUS_OPTIONS.find((option) => option.value === values.status)}
                    onChange={(e) => setFieldValue('status', e.value)}
                    errorText={errors.status && touched.status ? errors.status : null}
                    required
                  />
                  <InputBox
                    className="xl:col-span-6 col-span-12"
                    label="POS ID"
                    name="posId"
                    type="text"
                    placeholder="POS ID"
                    value={values.posId}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/^\s+/, ''); // Remove leading spaces
                      setFieldValue('posId', sanitizedValue); // Update Formik's value
                    }}
                    errorText={errors.posId && touched.posId ? errors.posId : null}
                    required
                    maxLength={10}
                  />
                </div>
                <div className="mt-6 grid grid-cols-2 gap-6">
                  <MultiLanguageInput
                    type="textarea"
                    label="Description"
                    id="category-description-text"
                    name="description"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </div>
                <div className="mt-6 grid grid-cols-1 gap-6">
                  <div>
                    <div className="flex items-center justify-end">
                      <button
                        type="button"
                        onClick={(e) => handleAddModifier(e, setFieldValue)}
                        className="ti-btn ti-btn-success ti-btn-loader m-1"
                      >ADD MODIFIERS</button>
                    </div>
                    {modifiersList.length > 0 && (

                      <table className="mt-6 ti-custom-table ti-custom-table-head ti-custom-table-hover w-full">
                        <thead>
                          <tr>
                            <th scope="col" className="w-1/4">Modifier</th>
                            <th scope="col" className="w-1/4">Price</th>
                            <th scope="col" className="w-1/4 !text-end">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {modifiersList.map((modifier, index) => (
                            <tr key={modifier.id} className="w-full">
                              <td className="w-3/5">
                                <SelectBox
                                  name={`modifiers[${index}].id`}
                                  options={availableModifiersOptions}
                                  value={modifiersOptions.find((option) => parseInt(option.id) === parseInt(modifier.id)) || null}
                                  onChange={(selectedOption) => handleSelectModifier(index, selectedOption, setFieldValue)}
                                  errorText={errors.modifiers?.[index]?.id || null}
                                />
                              </td>
                              <td className="w-2/5">
                                <InputBox
                                  name={`modifiers[${index}].price`}
                                  type="number"
                                  placeholder="Enter price"
                                  value={modifier.price}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value.replace(/[^\d.]/g, ''))
                                    handlePriceChange(index, value, setFieldValue);
                                  }}
                                  min={0}
                                  maxLength={PRICE_LIMIT}
                                  step="any"
                                  errorText={errors.modifiers?.[index]?.price || null}
                                  inputClassName="block w-full"
                                />
                              </td>
                              <td className="w-auto">
                                <button
                                  onClick={() => handleDeleteModifier(index, setFieldValue)}
                                  type="button"
                                  className="ti-btn ti-btn-danger ti-btn-icon ti-btn-sm"
                                >
                                  <i className="ri-delete-bin-5-line" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </OffCanvasForm>
  )
}

ModifierGroupForm.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    modGroupName: PropTypes.object.isRequired,
    description: PropTypes.object,
    modGroupType: PropTypes.string.isRequired,
    status: PropTypes.string,
    posId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    modLabel: PropTypes.string,
    modifiers: PropTypes.array.isRequired,
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
}

export default ModifierGroupForm
