// roles.js
import {
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  doc,
} from 'firebase/firestore'
import { db } from '../../../../src/firebase/firebaseapi'
import { COLLECTIONS, ROLE } from '../../constants/constants'


export const getAllRoles = async (limitValue = 10, startAfterDoc = null) => {
  try {
    const rolesCollectionRef = collection(db, COLLECTIONS.ROLES)
    const queryConstraints = [where('type', '==', ROLE.ROLE_TYPE_HUB), orderBy('createdAt'), limit(limitValue)]

    if (startAfterDoc) {
      queryConstraints.push(startAfter(startAfterDoc))
    }

    const rolesQuery = query(rolesCollectionRef, ...queryConstraints)
    const querySnapshot = await getDocs(rolesQuery)

    const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

    return {
      data,
      lastVisible,
    }
  } catch (error) {
    console.error('Error fetching role data:', error)
    return { data: [] }
  }
}
export const getUserRole = async (userId, type) => {
  try {
    const roleRef = doc(db, COLLECTIONS.USERS, userId, COLLECTIONS.ROLES, type)
    const roleDoc = await getDoc(roleRef)
    return roleDoc.exists() ? roleDoc.data() : null
  } catch (error) {
    console.error('Error fetching role data:', error)
  }
}

// Create a new role document in the ROLES collection
export const createRole = async (role) => {
  
  const newRoleName = role?.name?.trim().toLowerCase()

  // Reference to roles collection
  const roleRef = collection(db, COLLECTIONS.ROLES)

  try {
    // Fetch all roles from Firestore
    const querySnapshot = await getDocs(roleRef)

    // Extract and normalize names to lowercase
    const existingNames = querySnapshot.docs.filter(doc => doc.data()?.type ===  ROLE.ROLE_TYPE_HUB).map((doc) => doc.data()?.name?.trim().toLowerCase())

    // Check if the new role name already exists
    if (existingNames.includes(newRoleName)) {
      return Promise.resolve({
        success: false,
        message: 'A role with this name already exists. Please choose a different name.',
      })
    }

    // Create a new role with a unique ID
    const roleDocRef = doc(roleRef)
    await setDoc(roleDocRef, { 
      ...role, 
      id: roleDocRef.id, 
      nameLowerCase: newRoleName
    })

    return Promise.resolve({
      success: true,
      message: `The role "${role?.name?.trim()}" has been created successfully. You can now assign users to this role.`,
    })
  } catch (error) {
    console.error('Error checking or creating role:', error)
    return Promise.resolve({
      success: false,
      message: 'An error occurred while creating the role. Please try again.',
    })
  }
}

// Edit an existing role document by ID
export const editRole = async (id, role) => {
  const roleRef = doc(db, COLLECTIONS.ROLES, id)

  if (role.name) {
    const nameLowerCase = role?.name?.toLowerCase()
    const rolesCollection = collection(db, COLLECTIONS.ROLES)
    const q = query(
      rolesCollection,
      where('name', '==', nameLowerCase),
      where('id', '!=', id),
      where('type', '==', ROLE.ROLE_TYPE_HUB)
    )
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      return Promise.resolve({
        success: false,
        message: 'A role with this name already exists. Please choose a different name',
      })
    }
  }
  const roleData = { ...role, ...(role.name && { nameLowerCase: role.name.toLowerCase() }) }
  await updateDoc(roleRef, roleData)
  return Promise.resolve({
    success: true,
    message: 'You have successfully updated the role.',
  })
}
